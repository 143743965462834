

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import sdk from '@/sdk/InstoreSdk';
import { Message } from 'element-ui';

// banner组件通信传值设定：
// initOptions：swiper初始化相关参数
// page：getHeaderBanner的传参
// initSwiper：是否为轮播，默认非轮播
// data：自定义banner数据

@Component
export default class InsBanner extends Vue {
  @Prop() private initOptions!: object;
  @Prop({ default: '' }) private page!: string;
  @Prop({ default: false }) private initSwiper!: boolean;
  @Prop() private data!: any;
  bannerList: object[] = [];
  bannerImg: string = '';

  swiperOption: object = {
    autoplay: true,
    // autoplay: {
    //   disableOnInteraction: false
    // },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // },
    autoHeight: true, // 高度随内容变化
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    observeParents: true // 修改swiper的父元素时，自动初始化swiper
  };

  // get swiper () {
  //   return this.$refs.mySwiper.swiper;
  // }

  getBanner () {
    // sdk.api.promotion.getHeaderBanner(this.page).then(
    //   function (data) {
    //     _this.bannerList = data;
    //   },
    //   function (data) {
    //     Message({
    //       message: data,
    //       type: 'error'
    //     });
    //   }
    // );

    sdk.api.promotion.GetHeaderBannerBydevice(this.page, this.isMobile).then(
      (data) => {
        this.bannerList = data;
        console.log(this.bannerList, 'banners');
      },
      function (data) {
        Message({
          message: data,
          type: 'error'
        });
      }
    );
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  created () {
    if (this.initOptions) {
      this.swiperOption = this.initOptions;
    }
  }

  mounted () {
    // if (this.bannerList.length === 1) {
    // 只有1个slide，swiper会失效且隐藏切换按钮
    // this.swiper.destroy(false);
    // }

    if (this.page) {
      this.getBanner();
    } else {
      if (this.initSwiper) {
        this.bannerList = this.data;
      } else {
        if (typeof (this.data) === 'object') {
          this.bannerImg = this.data[0].Image;
        } else if (typeof (this.data) === 'string') {
          this.bannerImg = this.data;
        }
      }
    }
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    if (this.initSwiper) {
      this.bannerList = [];
      this.getBanner();
    }
  }
}
